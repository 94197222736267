import React from 'react';
import { graphql } from 'gatsby';
import {
  ItemsListIndexPage,
  PageHero,
  PageWithSubNav,
  Section,
} from '../../../components';
import pageHeroData from '../../../data/pages/components.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Themes Overview"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Themes" tierThree="Overview" />

      <Section>
        <ItemsListIndexPage
          data={data}
          tierTwo="themes"
          listType="visual"
          listLayout="3"
        />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query themesDesignOverviewQuery($tierOne: String = "Components") {
    ...indexList
  }
`;
